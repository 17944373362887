import * as React from "react";
import ShowList from '../../data/shows.json'

const Shows = () => {
    return (
        <section class="tour basic-block" id="shows">
            <div class="container">
                <header class="tour_header basic-header mb-5 pb-5">
                    <span class="basic-line">
                        <span class="basic-title my-3">Upcoming Shows</span>
                    </span>
                </header>
                <div class="tour-box row">
                    <div class="tour-wrapper col col-12 col-sm-8">
                        <h4 class="tour-title pl-4 ml-3">Upcoming Concerts</h4>
                        <ul class="tour-list">
                            {ShowList.shows.map(show => (
                                <li class="tour-item row">
                                    <datetime class="tour-date col col-2">{show.date}</datetime>
                                    <span class="tour-place col col-6">{show.place}</span>
                                    <span class="tour-tickets col col-3"> Tickets: <a class="tour-tickets-link" href={show.link}>Buy here</a></span>
                                    <p class="tour-description col col-6 offset-2">
                                        {show.description}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div class="tour-wrapper col col-12 col-sm-4">
                        <h4 class="tour-title pl-4 ml-3">Past Concerts</h4>
                        <ul class="tour-list"> 
                        {ShowList.past.map(show => (
                            <li class="tour-item">
                                <p class="tour-description">
                                    {show.description}
                                </p>
                            </li>
                            ))}
                            
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Shows;